@use 'src/assets/styles/abstracts/variables';

/* stylelint-disable */
// Override ng-select style for hub entity versioning ddl
.ng-select.drop-down-list.ng-select-opened > .ng-select-container {
  .ng-arrow {
    border-color: transparent transparent variables.$primary-hub;
    border-style: solid;
    border-width: 0 0.313rem 0.313rem;
  }
}

.ng-select.drop-down-list.ng-select-opened {
  .ng-select-container {
    color: variables.$primary-hub;
  }
}

.ng-select.drop-down-list.ng-select-focused {
  .ng-select-container {
    border: none;
    box-shadow: none;
  }
}

.ng-select.drop-down-list {
  .ng-arrow-wrapper {
    .ng-arrow {
      border-color: variables.$primary-hub transparent transparent;
      border-style: solid;
      border-width: 0.313rem 0.313rem 0.156rem;
    }
  }

  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option {
        background: white;

        .ng-option-label {
          display: flex;
          font-size: 0.9rem;
          font-weight: 500;
        }
      }
    }
  }

  .ng-select-container {
    border: none;
    box-shadow: none;

    &:hover,
    &.hover {
      color: variables.$primary-hub;
      cursor: pointer;
    }

    .ng-value-container {
      .ng-value {
        span {
          font-family: Roboto, serif;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 170%;
        }
      }
    }
  }
}

.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  position: relative;
  font-size: 0.875rem;

  &:last-child::after {
    content: unset;
  }

  &::after {
    background-color: #cbcccd;
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
  }
}

.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label {
  font-weight: bold;
}

// hover
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #fff;
  color: variables.$muted-navigation-hub;
}

// active
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: variables.$bg-sidenav-neutral-hub;
  color: variables.$muted-navigation-hub;
}

.wizard-cell--dropdown.ng-select {
  width: 100%;

  .ng-select-container {
    line-height: 2.375rem;
  }
}

.ng-select.ng-invalid.ng-touched {
  .ng-select-container {
    border-color: variables.$danger-hub !important;
  }
}

.is-valid .ng-select-container {
  border-color: variables.$success-hub;
}

.ng-select.ng-select-focused ~ .signup-form-label {
  background-color: #ffff;
  font-size: 0.75rem;
  left: 0.375rem;
  top: -0.813rem;
  z-index: 10000;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: variables.$success-hub;
  box-shadow: none;
}

.hub-test-module-connection--dropdown.ng-select.drop-down-list .ng-select-container {
  max-width: 8.125rem;
}

.hub-test-module-connection--dropdown .ng-dropdown-panel {
  right: 0;
  left: unset;
}

.ng-select.hub-ng-select-tertiary
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option
  .ng-option-label {
  font-weight: normal;
}

.ng-select.hub-ng-select-tertiary .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-weight: normal;
  color: variables.$light-text-hub;
}

// hover
.ng-select.hub-ng-select-tertiary
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-marked {
  background-color: variables.$light-hub;
  color: variables.$dark-hub;
}

// active
.ng-select.hub-ng-select-tertiary
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected
  .ng-option-label {
  font-weight: bold;
}

// active
.ng-select.hub-ng-select-tertiary
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected {
  background-color: variables.$light-hub;
  color: variables.$light-text-hub;
  font-weight: bold;
}

.ng-select.hub-ng-select-secondary
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option
  .ng-option-label {
  font-weight: normal;
}

.ng-select.hub-ng-select-secondary .ng-arrow-wrapper {
  .ng-arrow {
    border-color: variables.$secondary-hub transparent transparent;
  }
}

.ng-select.drop-down-list.hub-ng-select-secondary.ng-select-opened > .ng-select-container {
  .ng-arrow {
    border-color: transparent transparent variables.$secondary-hub;
  }
}

.ng-select.drop-down-list.hub-ng-select-secondary.ng-select-opened {
  .ng-select-container {
    color: variables.$secondary-hub;
  }
}

.ng-select.hub-ng-select-secondary .ng-select-container {
  border: none;
  box-shadow: none;

  &:hover,
  &.hover {
    color: variables.$secondary-hub;
    cursor: pointer;
  }
}

.ng-select.hub-ng-select-secondary .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-weight: normal;
  color: variables.$secondary-hub;
}

// hover
.ng-select.hub-ng-select-secondary
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-marked {
  background-color: variables.$bg-sidenav-neutral-hub;
  color: variables.$secondary-hub;
}

// active
.ng-select.hub-ng-select-secondary
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected
  .ng-option-label {
  font-weight: bold;
}

// active
.ng-select.hub-ng-select-secondary
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected {
  background-color: variables.$bg-sidenav-neutral-hub;
  color: variables.$secondary-hub;
  font-weight: bold;
}

.ng-select.hub-set-custom-expression-drop-down-list {
  .ng-arrow-wrapper .ng-arrow {
    display: none !important;
  }

  &.ng-select .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 7.5rem !important;
  }

  &.ng-select-single .ng-select-container .ng-value-container {
    padding-left: 1.5rem !important;
  }

  .ng-select-container .ng-value-container .ng-input > input {
    padding-left: 0.9rem !important;
  }

  &.custom .ng-clear-wrapper .ng-clear {
    font-size: 0.5rem !important;
  }

  .ng-clear-wrapper .ng-clear {
    font-size: 1.5rem !important;
  }
}

.manage-users-in-group-modal {
  .ng-dropdown-panel {
    width: auto !important;
  }
}

.type-block {
  border-radius: 0.125rem;
  height: 0.5rem;
  width: 0.5rem;
}
